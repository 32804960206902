@value colors: "../../styles/colors.module.css";
@value gray4, secondarySurfaceLightHover, textColorGreen, textDark, textLight from colors;

.thankYouContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: textLight;
  font-size: 1.3rem;
  font-weight: bold;
}

.thankYouContainer button {
  margin-top: 1.5rem;
}

.darkModeText {
  color: textDark;
}

.thankYouIcon {
  font-size: 6rem;
  color: textColorGreen;
  margin-bottom: 1rem;
}
