.ballsContainer {
  margin-top: 24px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 550px) {
  .ballsContainer {
    justify-content: center;
  }
}

@media only screen and (max-width: 419px) {
  .ballsContainer {
    justify-content: space-between;
  }
}
