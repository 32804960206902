.qrCodeContainer {
  margin: 50px;
  margin-bottom: 20px;
}

.qrCode {
  width: 100%;
  height: 100%;
}

.label {
  text-align: center;
  font-size: 2.5em;
}
