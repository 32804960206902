@value colors: "../../styles/colors.module.css";
@value gray5, textDark from colors;

.container {
  transition: 0.3s background;
  position: relative;
  background: textDark;
  z-index: 667;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  padding: 0 25px;
}

.containerDarkMode {
  background: gray5;
}

.side {
  display: flex;
  align-items: center;
}

.buttonsGap {
  gap: 15px;
}
