@value colors: "../../styles/colors.module.css";
@value strokeDarkSecondary, gray3, gray5, cinnabar, mainSurfaceLight, textLightSecondary, textDark, textLight, textDarkSecondary, strokeDark, strokeLight from colors;

.searchBoxContainer {
  width: inherit;
  margin-top: 1rem;
}

.buttonsContainer {
  margin-top: 1rem;
  width: inherit;
  overflow: auto;
}

.languageContainer {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid white;
  border-radius: 12px;
  margin-bottom: 0.5rem;
  color: textLight;
  border: 1px solid strokeLight;
  font-weight: bold;
  transition: 0.3s border-color, 0.3s color, 0.3s background-color;
  cursor: pointer;
}

.languageContainer:hover:not(
    .languageContainerSelected,
    .languageContainerDarkMode
  ) {
  background-color: strokeLight;
  border-color: textDarkSecondary;
}

.languageContainerDarkMode {
  color: textDarkSecondary;
  border-color: strokeDarkSecondary;
}

.languageContainerDarkMode:hover:not(.languageContainerSelected) {
  background-color: gray3;
}

.languageContainerSelected {
  background-color: cinnabar;
  border-color: cinnabar;
  color: textDark;
}

.englishName {
  font-style: italic;
  /* font-size: small; */
  font-weight: normal;
}

.noAvail {
  text-align: center;
  font-size: 1.1em;
}
