@value colors: "../../styles/colors.module.css";
@value textLight, textDark, gray2, gray3, gray5, strokeLight, cinnabar from colors;

.container {
  padding: 30px;
  width: 500px;
  font-family: Roboto;
  position: absolute;
}

.modalContainer {
  margin-top: 80px;
  padding: 20px;
  color: textLight;
  background: textDark;
  position: relative;
  border-radius: 10px;
  transition: 0.3s background, 0.3s color;
}

.video {
  opacity: 1;
  transition: 0.3s opacity;
}

.hideVideo {
  opacity: 0;
}

.modalContainerDarkMode {
  background: gray5;
  color: textDark;
}

.videoContainer {
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}

.loadingIndicator {
  z-index: 0;
}

.title {
  margin: 20px 0;
  font-size: 1.5em;
  font-weight: 700;
}

.text {
  line-height: 1.8;
  margin-bottom: 20px;
}

.button {
  background: cinnabar;
  color: white;
  font-size: 1.1em;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 10px;
  border: 0;
  outline: none;
  cursor: pointer;
}

.closeButton {
  position: absolute;
  top: -20px;
  right: -20px;
  background: strokeLight;
  width: 40px;
  height: 40px;
  color: textLight;
  font-size: 1.5em;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  transition: 0.3s color, 0.3s background;
}

.closeButtonDarkMode {
  color: textDark;
  background: gray3;
}

.icon {
  margin-right: 10px;
}
