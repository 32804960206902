@value colors: "../../../../styles/colors.module.css";
@value gray4,secondarySurfaceLight, textDarkSecondary, textLightSecondary from colors;

.container {
  /* color: textLightSecondary; */
  margin: 28px 0 0;
  font-weight: 700;
  transition: 0.3s color, 0.3s opacity;
}

.containerDarkMode {
  /* color: textDarkSecondary; */
}

.reference {
  margin-top: 8px;
  font-size: 0.7em;
}

.text {
  transition: 0.3s color;
}
