@value colors: "../../../styles/colors.module.css";
@value gray5, gray6, gray2, textDark, textLight, strokeLightSecondary, textDarkSecondary, gray2 from colors;

.container {
  margin: 10px 0;
  position: relative;
  overflow-y: auto;
  max-height: calc(100dvh - 215px);
}

.innerContainer {
}

.languageContainer {
  border-top: 1px solid strokeLightSecondary;
  margin-top: 5px;
  padding-top: 5px;
}

.languageContainerDarkMode {
  border-top: 1px solid gray2;
}

.languageContainer:first-of-type {
  margin-top: 0;
  border-top: none;
}
