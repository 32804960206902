@value colors: "../../styles/colors.module.css";
@value textLight, textDark from colors;

.container {
  display: flex;
  fill: textLight;
  transition: fill 0.3s;
  margin-right: 10px;
}

.containerDarkMode {
  fill: textDark;
}

.icon {
  margin-right: 5px;
  padding: 5px 0;
  box-sizing: border-box;
}

.onIcon {
  fill: white;
  height: 16px;
  margin-left: 5px;
}

.offIcon {
  fill: white;
  height: 16px;
  margin-left: 10px;
}
