@value colors: "../../styles/colors.module.css";
@value textDark, textLight, mainSurfaceDark, mainSurfaceLight, strokeDark, strokeDarkSecondary, strokeLight, textLightSecondary, textDarkSecondary from colors;

.container {
  display: flex;
  background: white;
  color: textLight;
  flex: 1;
  min-width: 100px;
  transition: background 0.3s, color 0.3s, border 0.3s;
  border: 1px solid strokeLight;
  border-radius: 12px;
  height: 55px;
  align-items: center;
}

.containerDarkMode {
  color: textDark;
  border: 1px solid strokeDarkSecondary;
  background: mainSurfaceDark;
}

.input {
  flex: 1;
  outline: none;
  color: textLight;
  background: none;
  border: none;
  padding: 0;
  width: 100%;
  font-family: Roboto, sans-serif;
  font-size: 1.1em;
  transition: color 0.3s;
  font-weight: 700;
}

.input::placeholder {
  color: textLightSecondary;
  font-weight: 300;
  transition: color 0.3s;
}

.inputDarkMode {
  color: textDark;
}

.inputDarkMode::placeholder {
  color: strokeDarkSecondary;
}

.icon {
  margin: 0 15px;
  font-size: 1.5em;
}

.clearIcon {
  font-size: 1.5em;
}

.clearButton {
  transition: color 0.3s, opacity 0.3s;
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}

.clearButtonHide {
  pointer-events: none;
  opacity: 0;
}

.clearButton:hover {
  color: textLightSecondary;
}

.clearButton:active {
  color: textLight;
}

.clearButtonDarkMode {
  color: textDark;
}

.clearButtonDarkMode:hover {
  color: textDarkSecondary;
}

.clearButtonDarkMode:active {
  color: textDark;
}
