@value colors: "../../../styles/colors.module.css";
@value textDark, textLight, strokeDarkSecondary, strokeLight, gray3, strokeLightSecondary, mainSurfaceLight, mainSurfaceDark, textDarkSecondary, textLightSecondary, cinnabar from colors;

.container {
  border-radius: 12px;
  border: 1px solid strokeLightSecondary;
  color: textLightSecondary;
  transition: 0.3s background, 0.3s border, 0.3s color;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  padding: 10px 0;
}

.container:hover {
  background: strokeLight;
}

.container:active {
  background: none;
}

.containerDarkMode {
  border: 1px solid strokeDarkSecondary;
  color: textDarkSecondary;
}

.containerDarkMode:hover {
  background: gray3;
}

.containerDarkMode:active {
  background: none;
}

.containerSelected,
.containerSelected:hover,
.containerSelected:active {
  border: 1px solid cinnabar;
  background: cinnabar;
  color: white;
  font-weight: 600;
}
