@value colors: "../../../styles/colors.module.css";
@value textDark, textLight, mainSurfaceDark, gray3, mainSurfaceLight, strokeDark, strokeDarkSecondary, strokeLightSecondary, strokeLight,strokeLightHover, textLightSecondary, textDarkSecondary, cinnabar from colors;

.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  outline: none;
  cursor: pointer;
  border: 1px solid strokeLight;
  color: textLightSecondary;
  font-size: 1.1em;
  transition: 0.3s background, 0.3s color, 0.3s border;
}

.container:hover {
  border: 1px solid strokeLight;
  background: strokeLight;
}

.container:active {
  border: 1px solid strokeLight;
  background: none;
}

.containerDarkMode {
  color: textDarkSecondary;
  border: 1px solid strokeDarkSecondary;
}
.containerDarkMode:hover {
  background: gray3;
  border: 1px solid strokeDarkSecondary;
}

.containerDarkMode:active {
  background: none;
  border: 1px solid strokeDarkSecondary;
}

.containerSelected,
.containerSelected:hover,
.containerSelected:active {
  background: cinnabar;
  color: white;
  font-weight: 900;
  border: 1px solid cinnabar;
}

.container:first-child {
  border-radius: 10px 0 0 10px;
  border-right: 0;
}

.container:last-child {
  border-radius: 0 10px 10px 0;
  border-left: 0;
}
