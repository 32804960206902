@value colors: "../../styles/colors.module.css";
@value strokeDark, strokeLight from colors;

.outerContainer {
  box-sizing: border-box;
  position: absolute;
  padding-top: 60px;
  overflow-y: auto;
  overflow-x: visible;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  transition: 0.3s padding-top;
}

.outerContainerMobileWithTab {
  padding-top: 100px;
}

.outerContainerMobile {
  padding-top: 0;
}

.sideContainer {
  color: white;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.innerContainer {
  transition: 0.3s border-bottom, 0.3s opacity, 0.3s color;
  position: relative;
  box-sizing: border-box;
}

.video {
  width: 100%;
  height: auto;
}

.arrowsContainer {
  display: flex;
  flex-direction: row;
}
