@value colors: "../../../styles/colors.module.css";
@value mainSurfaceDarkTranslucent, mainSurfaceDark, gray3, gray5, gray4, gray1, mainSurfaceLight, textDark, textLight, cinnabar from colors;

.container {
  background: mainSurfaceDark;
  width: 35px;
  height: 35px;

  margin-bottom: 7.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: textDark;
  fill: textDark;
  border-radius: 5px;
  font-size: 1.2em;
  cursor: pointer;
  transition: 0.3s background, 0.3s transform, 0.3s border;
}

.containerEmphasized {
  /* transform: scale(1.2); */
  /* border: 1px solid #ffffff99; */
}

.container:hover {
  background: gray4;
}

.container:active {
  background: mainSurfaceDark;
}

.downloadIcon {
  padding: 9px;
}

.containerActive,
.containerActive:hover,
.containerActive:active {
  background: cinnabar;
}

.containerDisabled,
.containerDisabled:hover,
.containerDisabled:active {
  cursor: none;
  opacity: 0.8;
}

.tooltip {
  font-size: 1.05em;
}
