.container {
  cursor: pointer;
  height: 40px;
  display: block;
  transition: 0.3s fill;
  position: absolute;
  left: 50%;
  transition: 0.3s margin-left;
}

.img {
  margin: 0 auto;
  height: 100%;
}
