.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flagContainer {
  margin: 0px 20px;
}

.nameContainer {
  font-size: 1.3em;
}
