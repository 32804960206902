@value colors: "../../styles/colors.module.css";
@value textLightSecondary, textDarkSecondary from colors;

.container {
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: textLightSecondary;
  transition: 0.3s color;
  cursor: pointer;
}

.containerDarkMode {
  fill: textDarkSecondary;
}

.icon {
  /* font-size: 2.2em; */
  font-size: 40px;
}
