@value colors: "../../styles/colors.module.css";
@value secondarySurfaceLightHover, gray4, cinnabar, textDark, textLight from colors;

.errorMessageContainer {
  background-color: secondarySurfaceLightHover;
  color: textLight;
  padding: 0.8rem;
  border-radius: 8px;
  padding: 0.8rem 0;
  border-left: 6px solid cinnabar;
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.errorMessageDarkModeContainer {
  background-color: gray4;
  color: textDark;
}

.errorIcon {
  color: cinnabar;
  font-size: 1.5rem;
  margin: 0 1rem;
}
