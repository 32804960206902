@value colors: "../../../styles/colors.module.css";
@value mainSurfaceDarkTranslucent, mainSurfaceDark, gray3, gray5, gray4, gray1, mainSurfaceLight, textDark, textLight, cinnabar from colors;

.container {
  position: absolute;
  top: 7.5px;
  right: 7.5px;
  z-index: 10000000000000;
  display: flex;
  padding: 7.5px;
  flex-direction: column;
  border-radius: 5px;
  align-items: center;
  transition: 0.3s opacity, 0.3s transform, 0.3s background;
  transform-origin: right top;
}

.containerClipModeActive {
  /* transform: scale(1.1); */
  background: cinnabar;
}

.containerHide {
  opacity: 0;
  pointer-events: none;
}

.clipIcon {
  margin-top: 2px;
  display: block;
  color: textDark;
  opacity: 0;
  transition: 0.3s opacity;
}

.clipIconShow {
  opacity: 1;
}
