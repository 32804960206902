.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 10px;
}

.containerDarkMode {
}

.iconContainer {
  margin-bottom: 5px;
}

.iconContainer:last-child {
  margin-bottom: 0;
}
