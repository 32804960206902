@value colors: "../../styles/colors.module.css";
@value gray5, textDark, textDarkSecondary, textLightSecondary, gray7 from colors;

.container {
  position: absolute;
  right: 0;
  top: 113px;
  outline: none;
  cursor: pointer;
  display: flex;
  width: 82px;
  height: 57px;
  align-items: center;
  background: textDark;
  color: textLightSecondary;
  fill: textLightSecondary;
  overflow: hidden;
}

.containerNoIcon {
  width: 35px;
}

.containerDarkMode {
  color: textDarkSecondary;
  fill: textDarkSecondary;
  background: gray5;
}

.arrowContainer {
  display: flex;
  width: 30px;
  justify-content: flex-start;
  align-items: center;
}

.arrow {
  width: 20px;
  height: 20px;
  transition: 0.6s transform;
}

.iconContainer {
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
}
