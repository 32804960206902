@value colors: "../../../styles/colors.module.css";
@value gray6, cinnabar, gray2, gray1, textLight, textDark, textDarkSecondary from colors;

.container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10px;
  width: 90px;
  height: 50px;
  transition: fill 0.3s, background 0.3s, color 0.3s, border 0.3s;
  color: textLight;
  overflow-y: auto;
  fill: textLight;
  border: 1px solid gray1;
  cursor: pointer;
  margin: 5px 10px 5px 0;
}
.container:last-of-type {
  margin-right: 0;
}

.containerDarkMode {
  background: gray6;
  border: 1px solid gray2;
  color: textDarkSecondary;
  fill: textDarkSecondary;
}

.containerSelected {
  background: cinnabar;
  color: textDark;
  fill: textDark;
}

.content {
  /*margin: 20% 20% 15%;*/
  margin: 5px 0;
  width: auto;
}

.check {
  height: 20px;
  width: 20px;
  border: 1px solid gray2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  transition: background 0.3s, border 0.3s;
  color: textDark;
}
.checkDarkMode {
  border: 1px solid gray2;
}

.checkSelected {
  background: cinnabar;
  border: 1px solid cinnabar;
}

.checkIcon {
  font-size: 0.6em;
  opacity: 0;
  transition: opacity 0.3s;
}

.checkIconShow {
  opacity: 1;
}

.squareAspectRatio {
  aspect-ratio: 1 / 1;
}
