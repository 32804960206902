@value colors: "../../styles/colors.module.css";
@value gray5, mainSurfaceLight, textLightSecondary, textDark, textLight, textDarkSecondary from colors;

.container {
  max-width: 35.25rem;
  width: 100%;
  background-color: gray5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 36px;
  color: textDark;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.lightMode {
  background-color: mainSurfaceLight;
  color: textLight;
}

.childrenContainer {
  width: inherit;
  position: relative;
  overflow: auto;
}

.isMobile {
  max-width: 100%;
  justify-content: flex-start;
  border-radius: 0;
}
