.enter {
  opacity: 0;
  pointer-events: none;
  transform: scale(0.8);
}

.enterActive {
  opacity: 1;
  transition: 300ms opacity, 300ms transform;
  transform: scale(1);

  pointer-events: active;
}

.enterDone {
  opacity: 1;
  transform: scale(1);

  pointer-events: active;
}

.exit {
  opacity: 1;
  transform: scale(1);

  pointer-events: none;
}

.exitActive {
  transition: 300ms opacity, 300ms transform;
  opacity: 0;
  transform: scale(0.8);

  pointer-events: none;
}

.exitDone {
  opacity: 0;
  transform: scale(0.8);
  pointer-events: none;
  /* display: none; */
}
