@value colors: "../../styles/colors.module.css";
@value gray5, textDark, textLight, aliceBlue, aliceBlueHover, lightGray, spaceCadet, gainsboro, spaceCadetLight, raisinBlack, raisinBlackHover, redMunsell from colors;

.container {
  width: 55px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
  white-space: nowrap;
}

.container:first-child {
  margin-left: 6px;
}

.container:last-child {
  margin-right: 0;
}

.ball {
  width: 55px;
  height: 55px;
  margin-bottom: 16px;
  border-radius: 100%;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: large;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7em;
  color: white;
  transition: 0.3s filter;
}

.ball:hover {
  filter: brightness(60%);
}

.ball.focusLightStroke:focus {
  outline: 2px solid textLight;
}

.ball:focus.focusDarkStroke:focus {
  outline: 2px solid textDark;
}

.label {
  color: textDark;
}

.labelLightMode {
  color: textLight;
}

@media only screen and (max-width: 550px) {
  .container:first-child,
  .container:last-child {
    margin: 16px;
  }
}

.tooltip {
  font-family: Roboto, sans-serif;
  font-size: 1rem !important;
}

.tooltipLight {
  background-color: strokeLightHover;
}

.tooltipDark {
  background-color: strokeDarkHover;
}

.textLight {
  color: textLight;
}

.textDark {
  color: textDark;
}
