@value colors: "../../styles/colors.module.css";
@value gray5, gray7, cinnabar, mainSurfaceLight, mainSurfaceDark, textLightSecondary, textDark, textLight, textDarkSecondary, strokeDark, strokeLight, strokeLightHover, strokeDarkHover from colors;

.container {
  width: 300px;
  display: flex;
  place-self: center;
  margin: auto;
  align-self: center;
  justify-self: center;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  padding: 15px;
  background: mainSurfaceLight;
  color: textLight;
}

.containerDarkMode {
  background: mainSurfaceDark;
  color: textDark;
}

.progressText {
  height: 40px;
  font-weight: 700;
  line-height: 40px;
  font-size: 1.2em;
}

/*.container {*/
/*  width: 500px;*/
/*  !*height: 500px;*!*/
/*  background: blue;*/
/*  display: flex;*/
/*  align-content: center;*/
/*  align-items: center;*/
/*}*/
