@value colors: "../../styles/colors.module.css";
@value overlayBackground, gray5, mainSurfaceDark, mainSurfaceLight, textDark, textLight from colors;

.container {
  position: absolute;
  transform-origin: top center;
  border: none;
  z-index: 1000;
  border-radius: 0 0 12px 12px;
  right: 0;
  box-sizing: border-box;
}

.header {
  background: textDark;
  transition: 0.3s background;
}

.headerDarkMode {
  background: gray5;
}

.innerContainer {
  background: textDark;
  box-sizing: border-box;
  border-radius: 0 0 12px 12px;
  transition: 0.3s transform, 0.3s background;
  transform-origin: top center;
  will-change: transform;
}

.innerContainerDarkMode {
  background: gray5;
}

.contentContainer {
  overflow-y: auto;
  transition: 0.3s background;
  background: textDark;
  box-sizing: border-box;
}

.contentContainerDarkMode {
  background: gray5;
}

.translationBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  margin-right: 30px;
}

.searchBoxContainer {
  margin: 0 20px 20px 20px;
}

.testamentTabsContainer {
  margin: 0 20px 20px 20px;
}

.mobileTranslationBoxContainer {
  margin: 0 20px 20px 20px;
  display: flex;
  justify-content: flex-end;
}
