@value colors: "../../../styles/colors.module.css";
@value mainSurfaceDark, mainSurfaceLight, textDark, textLight, textDarkSecondary, textLightSecondary from colors;

.button {
  display: flex;
  background: none;
  outline: none;
  border: 0;
  cursor: pointer;
  color: textLight;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  line-height: 1;
  padding: 5px 0;
  font-size: 1.02em;
  border-bottom: 1px solid mainSurfaceLight;
  /* border-bottom: 1px solid aliceBlueHover; */
  transition: 0.3s color, 0.3s border-bottom;
}

.button:hover {
  border-bottom: 1px solid textLight;
}

.button:active {
  border-bottom: 1px solid mainSurfaceLight;
}

.buttonDarkMode {
  color: textDark;
  border-bottom: 1px solid mainSurfaceDark;
}

.buttonDarkMode:hover {
  border-bottom: 1px solid textDark;
}

.buttonDarkMode:active {
  border-bottom: 1px solid mainSurfaceDark;
}

.icon {
  height: 15px;
  margin-top: 2px;
  margin-right: -8px;
  margin-left: -8px;
}

.text {
  font-family: Roboto, sans-serif;
  font-weight: 500;
}
