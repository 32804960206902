@value colors: "../../../styles/colors.module.css";
@value textDarkSecondary, gray6  from colors;

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: textDarkSecondary;
  width: 18px;
  height: 18px;
  pointer-events: none;
  border-radius: 10px;
  transition: background 0.3s;
}

.containerDarkMode {
  background: gray6;
}
