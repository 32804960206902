@value colors: "../../styles/colors.module.css";
@value cinnabar, textDark, textLight, strokeLight, secondarySurfaceLightHover, gray1, gray2, gray3, gray4 from colors;

.button {
  border-radius: 4px;
  padding: 0 1rem;
  height: 2.8rem;
  font-size: 1em;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
}

.primaryButton {
  background-color: cinnabar;
  border: 1px solid cinnabar;
  border-radius: 4px;
  color: textDark;
  transition: 0.3s filter;
}

.primaryButton:hover {
  filter: brightness(60%);
}

.secondaryButton {
  transition: 0.3s color, 0.3s background-color;
  background-color: transparent;
  border: 1px solid gray1;
  color: textLight;
}

.secondaryButton:hover {
  background-color: strokeLight;
}

.darkModeSecondaryButton {
  border: 1px solid gray2;
  color: textDark;
}

.darkModeSecondaryButton:hover {
  background-color: gray3;
}

.lighterSecondaryButton {
  background-color: secondarySurfaceLightHover;
  border: none;
}

.lighterSecondaryButton:hover {
  background-color: strokeLight;
}

.darkModeLighterSecondaryButton {
  background-color: gray4;
}

.darkModeLighterSecondaryButton:hover {
  background-color: gray3;
}

.icon {
  color: textLight;
}

.darkModeIcon {
  color: textDark;
}

.leftIcon {
  margin-right: 0.5rem;
}

.rightIcon {
  margin-left: 0.5rem;
}

.morePadding {
  padding: 0 2rem;
}
