@value colors: "../../styles/colors.module.css";
@value textDark, textLight, mainSurfaceLight, mainSurfaceDark, secondarySurfaceLight, secondarySurfaceLightHover, strokeLight, gray4, gray1, mainSurfaceDarkHover, cinnabar from colors;

.container {
  display: flex;
  height: 50px;
  cursor: pointer;
  transition: 0.3s background, 0.3s transform, 0.3s box-shadow, 0.3s opacity;
  background: secondarySurfaceLight;
  border-radius: 10px;
  margin-left: 10px;
  outline: none;
}

.innerContainer {
  transition: 0.3s color;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container:hover {
  background: strokeLight;
}

.container:active {
  background: secondarySurfaceLight;
}

.containerDarkMode {
  background: mainSurfaceDark;
  fill: textDark;
}

.containerDarkMode:hover {
  box-shadow: 0 0 5px gray4;
  background: mainSurfaceDarkHover;
}

.containerDarkMode:active {
  background: mainSurfaceDark;
}

.containerIsOpen,
.containerIsOpen:hover,
.containerIsOpen:active {
  transform: scale(1.05);
  background: cinnabar;
  color: textDark;
}

.disabledContainer {
  opacity: 0.4;
  pointer-events: none;
  cursor: auto;
}

.iconContainer {
  height: 33px;
  box-sizing: border-box;
  display: flex;
  margin-left: 13px;
  margin-right: 13px;
  flex-direction: row;
  align-items: center;
}

.textContainer {
  margin-right: 16px;
  font-weight: 700;
}

.arrow {
  transition: 0.3s transform, 0.3s fill;
  transform-origin: center;
  transform: rotate(-90deg);
  fill: textLight;
  width: 10px;
  height: 100%;
  margin-right: 8px;
}

.arrowDarkMode {
  fill: textDark;
}

.arrowIsOpen {
  transform: rotate(90deg);
  fill: textDark;
}
