.container {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.container:first-child {
  margin-left: 0;
}

.label {
  /* color: textLightSecondary; */
  transition: 0.3s color;
  margin-left: 7px;
}

.labelDarkMode {
  /* color: textDarkSecondary; */
}

.labelMobile {
  font-size: 1.1em;
}
