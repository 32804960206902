.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  transition: transform 0.3s, opacity 1.2s;
  transition-timing-function: ease-out;
}

.innerContainer {
  position: relative;
  max-width: 750px;
  width: 100%;
}
