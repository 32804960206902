@value colors: "../../styles/colors.module.css";
@value cinnabar, gray6, gray3, gray5, gray1, mainSurfaceLight, textLightSecondary, textDark, textLight, textDarkSecondary, strokeDark, strokeLight, strokeLightHover, strokeDarkHover from colors;

.container {
  width: 100%;
  height: 40px;
  position: relative;
  border-radius: 10px;
  background: gray6;
  overflow: hidden;
}

.containerDarkMode {
  background: gray3;
}

.text {
  position: absolute;
  width: 100%;
  height: 100%;
  color: white;
  font-weight: 900;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
}

.progress {
  background: cinnabar;
  overflow: hidden;
  z-index: 0;
  height: 40px;
  position: absolute;
  transition: width 0.3s;
  /* border-radius: 10px; */
}
