@value colors: "../../../styles/colors.module.css";
@value textLight, textDark, gray1 from colors;

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  /* color: gray1; */
  margin-top: 50px;
  text-align: center;
  font-weight: 400;
}

.icon {
  font-size: 2em;
}

.text {
  margin-top: 20px;
}

.secondaryText {
  font-size: 0.75em;
  font-weight: 300;
}
