.container {
  overflow: hidden;
}

.flag {
  transform-origin: top left;
  will-change: transform;
}

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.placeholderIcon {
  font-size: 1.5em;
}
