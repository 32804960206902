@value colors: "../../../styles/colors.module.css";
@value textLight, textDark  from colors;

.container {
  padding: 30px 0;
  box-sizing: border-box;
  overflow-y: scroll;
  display: flex;
  transition: 0.3s opacity;
  align-items: center;
  flex-direction: column;
  width: 100%;
  user-select: none;
}

.error {
  font-size: 1em;
  padding: 0 20px;
  color: textLight;
  transition: 0.3s color;
}

.errorDarkMode {
  color: textDark;
}
