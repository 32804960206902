@value colors: "../../../styles/colors.module.css";
@value gray4,gray5,secondarySurfaceLight, textDarkSecondary, textLightSecondary from colors;

.container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.containerMobile {
  align-items: flex-start;
  flex-direction: column;
}

.buttonsContainer {
  display: none;
  flex-direction: row;
}

.buttonsContainerMobile {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.switchesContainer {
  display: flex;
  align-items: center;
}

.switchesContainerMobile {
  width: 100%;
  justify-content: space-between;
  padding-bottom: 15px;
}
