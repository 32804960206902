@value colors: "../../../styles/colors.module.css";
@value cinnabar, gray4, strokeLight, textLight, textDark from colors;

.verseContent {
  flex: 1;
}

.clipboardButton {
  position: absolute;
  right: 2.5px;
  bottom: 2.5px;
  height: 40px;
  width: 40px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  border: 0;
  background: strokeLight;
  color: textLight;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s, background 0.3s;
}

.clipboardButtonDarkMode {
  color: textDark;
  background: gray4;
}

.clipboardButtonShow {
  opacity: 1;
  pointer-events: auto;
}

.clipboardButtonIcon {
  font-size: 1.5em;
}
