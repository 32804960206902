@value colors: "../../../styles/colors.module.css";
@value cinnabar from colors;

.container {
  height: 26px;
  width: 26px;
  background: cinnabar;
  position: absolute;
  border-radius: 14px;
  left: -4px;
  z-index: 1000000000;
}
