@value breakpoints: "../../../../styles/breakpoints.module.css";
@value mobile from breakpoints;

@value colors: "../../../../styles/colors.module.css";
@value gray3, gray4,gray5,secondarySurfaceLight, strokeLight, textDarkSecondary, textLightSecondary, cinnabar from colors;

.container {
  cursor: pointer;
  transition: 0.3s background, 0.3s color;
  padding: 10px 25px;
  color: textLightSecondary;
  margin: 7px;
  border-radius: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 1.15em;
  display: flex;
}

.container:hover {
  background: strokeLight;
}

.container:active {
  background: none;
}

.containerDarkMode {
  color: textDarkSecondary;
}

.containerDarkMode:hover {
  background: gray3;
}

.containerDarkMode:active {
  background: none;
}

.containerMobile {
  font-size: 1em;
}

.containerSelected,
.containerSelected:hover,
.containerSelected:active {
  background: cinnabar;
  color: white;
  font-weight: 700;
}

.iconContainer {
  margin-right: 10px;
}

@media screen and (max-width: mobile) {
  .container {
    font-size: 0.9em;
  }
}
