@value colors: "../../styles/colors.module.css";
@value gray1, gray2, gray3, gray5, gray4, strokeLight, textLightSidebar, textDarkSecondary, textLight from colors;

.externalLinkItem {
  height: 90px;
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  margin: 15px;
  transition: 0.3s border, 0.3s color, 0.3s background;
  border: 1px solid strokeLight;
  cursor: pointer;
}

.externalLinkItem:hover {
  background: strokeLight;
}

.externalLinkItem:active {
  background: none;
}

.externalLinkItemDarkMode {
  border: 1px solid gray1;
  color: textDarkSecondary;
}

.externalLinkItemDarkMode:hover {
  background: gray3;
}

.iconContainer {
  display: flex;
  align-items: center;
  margin: 16px 22px 16px 16px;
}

.icon {
  height: 55px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  line-height: 1;
  margin: 22px 0;
}

.title {
  font-weight: 900;
  font-size: 1.2em;
}

.description {
  font-size: 0.9em;
  /* transition: 0.3s color; */
  /* color: textLightSidebar; */
}

.descriptionDarkMode {
  /* color: textDarkSecondary; */
}
