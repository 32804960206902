@value colors: "../../styles/colors.module.css";
@value gray1, gray2, gray5, gray4, textDarkSecondary, textLight from colors;

.moonIcon {
  height: 20px;
  margin-left: 4px;
  color: gray5;
}

.sunIcon {
  fill: white;
  height: 16px;
  margin-left: 5px;
}

.iconContainer {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
