.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-top: 1px solid white;
}

.container:first-of-type {
  border-top: 0;
}

.label {
  font-size: 1.15em;
  font-weight: 700;
}

.code {
  font-size: 0.8em;
}
