@value colors: "../../../styles/colors.module.css";
@value gray6, gray2,strokeLightSecondary, textDarkSecondary, textLightSecondary, textDark, textLight from colors;

.container {
  border: 1px solid strokeLightSecondary;
  border-radius: 12px;
  height: 55px;
  padding-left: 5px;
  box-sizing: border-box;
  outline: none;
  display: flex;
  color: textLightSecondary;
  flex-direction: row;
  align-items: center;
  user-select: none;
  flex: 1;
  cursor: pointer;
  transition: background 0.3s, border 0.3s, color 0.3s;
}

.containerDarkMode {
  background: gray6;
  color: textDarkSecondary;
  border: 1px solid gray2;
}

.containerNoBorder {
  border: unset;
}

.name {
  flex: 1;
  &,
  & > * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 0;
  }
  padding-left: 10px;
}

.bookIcon {
  font-size: 30px;
  padding: 10px;
  color: textLight;
  transition: color 0.3s;
}

.bookIconDarkMode {
  color: textDark;
}

.arrow {
  fill: black;
  height: 20px;
  width: 20px;
  transform: rotate(-90deg);
  padding: 10px;
  transition: 0.3s transform, 0.3s fill;
}

.arrowFlip {
  transform: rotate(90deg);
}

.arrowDarkMode {
  fill: textDarkSecondary;
}
