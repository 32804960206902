@value colors: "../../styles/colors.module.css";
@value textDark, textLight, textDarkSecondary, textLightSecondary, cinnabar from colors;

.container {
  display: flex;
  overflow: visible;
  align-items: center;
}

.switchTranslationButton {
  display: inline-block;
  background: textLight;
  color: textDark;
  vertical-align: middle;
  border-radius: 12px;
  transition: 0.3s color, 0.3s background;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
  outline: none;
}

.switchTranslationButtonDarkMode {
  background: textDark;
  color: textLight;
}

.switchTranslationButton:hover {
  background: textLightSecondary;
}

.switchTranslationButton:active {
  background: textLight;
}

.switchTranslationButtonDarkMode:hover {
  background: textDarkSecondary;
}

.switchTranslationButtonDarkMode:active {
  background: textDark;
}

.teamIcon {
  display: inline-block;
  height: 40px;
  vertical-align: middle;
}

.code {
  height: 40px;
  font-family: Roboto;
  font-weight: 700;
  font-size: 1em;
  line-height: 40px;
  vertical-align: middle;
  display: inline-block;
  margin: 0 5px;
}

.teamIconImg {
  height: 100%;
}

.teamInfo {
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: 0.3s color;
  line-height: 40px;
  margin: 5px;
  color: raisinBlack;
  font-size: 2em;
  text-align: center;
}

.teamInfo:hover {
  color: raisinBlackHover;
}

.teamInfo:active {
  color: raisinBlack;
}

.teamInfoDarkMode {
  color: aliceBlue;
}

.teamInfoDarkMode:hover {
  color: aliceBlueHover;
}

.teamInfoDarkMode:active {
  color: aliceBlue;
}

.switchCountryButton {
  height: 40px;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.switchCountryButton:hover .flagImg {
  opacity: 0.8;
}

.switchCountryButton:active .flagImg {
  opacity: 1;
}

.flagImg {
  transition: 0.3s opacity;
  height: 100%;
}

.subscribeButtonContainer {
  background: cinnabar;
  color: white;
  font-family: Roboto;
  font-weight: 700;
  font-size: 1em;
  line-height: 40px;
}

.subscribeButton {
  display: flex;
  align-items: center;
  padding: 0 5px;
  justify-content: space-between;
  width: 105px;
}

.subscribeButton:hover {
  background: white;
  color: black;
  border-radius: inherit;
}
