@value colors: "../../../styles/colors.module.css";
@value gray4, gray3, gray4, gray5, gray6, cinnabar, lightGray, gray2, gray1, textLight, textDark, textDarkSecondary from colors;

.container {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  width: 100%;
  border-left: 7px solid #00000000;
}

.containerSelectable {
  padding: 10px 8px;
  cursor: pointer;
  position: relative;
  transition: background 0.3s, border-left 0.3s;
}

.containerBorder {
  border-bottom: 1px solid gray4;
  padding: 20px 8px;
}

.containerBorder:last-of-type {
  border-bottom: none;
}

.containerSelectable:hover {
  background: white;
}

.containerSelectableDarkMode:hover {
  background: gray6;
}

.containerSelectable:active {
  background: none;
}

.containerRounded {
  border-radius: 0 10px 10px 0;
}

.containerSelected,
.containerSelected:hover,
.containerSelected:active {
  background: lightGray;
  color: textLight;
  border-left: 7px solid cinnabar;
  cursor: auto;
}

.containerSelectedDarkMode,
.containerSelectedDarkMode:hover,
.containerSelectedDarkMode:active {
  background: gray5;
  color: textDark;
}
