.container {
  opacity: 1;
  transform: scale(1);
  transition: 0.3s transform, 0.3s opacity;
  position: relative;
  z-index: 2;
  pointer-events: auto;
  border-radius: 12px;
  height: 100%;
  width: 100%;
}

.hideContainer {
  opacity: 0;
  pointer-events: none;
  transform: scale(0.5);
}

.containerEmbedded {
  border-radius: 0;
}
