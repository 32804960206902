.iconContainer {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  transition: color 0.3s, border 0.3s;
}

.icon {
  font-size: 0.8em;
}
