.overlay {
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  transition: 0.3s opacity, 0.3s background;
  will-change: opacity, background;
}
