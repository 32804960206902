@value colors: "../../../../styles/colors.module.css";
@value textDark, textLight, strokeDarkSecondary, strokeLightSecondary, strokeLight, strokeLightHover, mainSurfaceLight, mainSurfaceDark, textDarkSecondary, textLightSecondary, gray5,secondarySurfaceLight,gray3,  gray4, cinnabar from colors;

.container {
  display: flex;
  flex: 1;
}

.button {
  cursor: pointer;
  transition: 0.3s border, 0.3s color, 0.3s background;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 5px;
  align-items: center;
  font-size: 0.9em;
  justify-content: center;
  border: 1px solid strokeLightSecondary;
  color: textLightSecondary;
  outline: 0;
  padding: 15px 0 10px 0;
}

.button:hover {
  background: strokeLight;
}

.button:active {
  background: none;
}

.buttonDarkMode {
  color: textDarkSecondary;
  background: gray5;
  border: 1px solid strokeDarkSecondary;
}

.buttonDarkMode:hover {
  background: gray3;
}

.buttonDarkMode:active {
  background: gray5;
}

.buttonSelected,
.buttonSelected:hover,
.buttonSelected:active {
  background: cinnabar;
  color: textDark;
  border: 1px solid cinnabar;
  font-weight: 700;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.icon {
  font-size: 2em;
}

.textContainer {
  font-size: 0.8em;
}
