@value colors: "../../../../styles/colors.module.css";
@value gray4, secondarySurfaceLight, textLightSecondary, textDarkSecondary from colors;

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: secondarySurfaceLight;
  transition: 0.3s background;
  padding: 20px;
  gap: 5px;
}

.containerDarkMode {
  background: gray4;
}

.hide {
  display: none;
}
