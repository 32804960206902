@value colors: "../../../styles/colors.module.css";
@value cinnabar, gray2 from colors;

.nivCopyright {
  border-top: 1px solid gray2;
  font-size: 0.7em;
  padding-top: 20px;
  margin: 20px;
  transition: 0.3s color;
}

.nivCopyrightLink {
  padding-left: 10px;
  color: cinnabar;
}
