@value colors: "../../../styles/colors.module.css";
@value overlayBackground, gray5, gray3, strokeLight, mainSurfaceDark, mainSurfaceLight, textDark, textLight, textLightSecondary, textDarkSecondary from colors;

.container {
  display: flex;
  flex-direction: row;
  height: 33px;
  transition: 0.3s color, 0.3s fill, 0.3s background;
  color: textLightSecondary;
  fill: textLightSecondary;
  background: white;
  align-items: center;
  justify-content: center;
  padding: 0 18px;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 0.9em;
  cursor: pointer;
}

.container:hover {
  background: strokeLight;
}

.container:active {
  background: white;
}

.containerDarkMode {
  color: textDarkSecondary;
  fill: textDarkSecondary;
  background: gray5;
}

.containerDarkMode:hover {
  background: gray3;
}

.containerDarkMode:active {
  background: gray5;
}

.containerMobile {
  width: 50%;
  height: 45px;
  margin: 0 10px;
  font-size: 1.1em;
}

.containerMobile:first-child {
  margin-left: 0;
}

.containerMobile:last-child {
  margin-right: 0;
}

.iconContainer {
  margin-right: 5px;
}

.textContainer {
  margin-left: 5px;
}
