@value colors: "../../styles/colors.module.css";
@value textLightSecondary, textDark, textLight, textDarkSecondary from colors;

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: small;
}

.heading {
  margin: 0;
}

.closeButton {
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  padding: 0;
  transition: 0.3s color;
}

.darkModeButton,
.darkModeButton:active {
  color: textDark;
}

.darkModeButton:hover {
  color: textDarkSecondary;
}

.lightModeButton,
.lightModeButton:active {
  color: textLight;
}

.lightModeButton:hover {
  color: textLightSecondary; /* It's here, but it doesn't show difference */
}

.xMarkIcon {
  font-size: 2.6em;
}
