@value colors: "../../../styles/colors.module.css";
@value gray6, textDarkSecondary from colors;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 4px;
  justify-content: space-around;
  box-sizing: border-box;
  cursor: pointer;
  overflow-x: visible;
}

.rangeContainer {
  position: relative;
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  /*padding: 0 20px;*/
}

.range {
  height: 6px;
  width: 100%;
  margin: 8px 0;
  background: textDarkSecondary;
  display: flex;
  flex-direction: row;
  pointer-events: none;
  align-items: center;
  justify-content: space-between;
  transition: background 0.3s;
}

.rangeDarkMode {
  background: gray6;
}

.labels {
  padding: 5px 5px 0 5px;
  display: flex;
  box-sizing: border-box;
  pointer-events: none;
  justify-content: space-between;
  width: 100%;
}
