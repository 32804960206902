@value colors: "../../styles/colors.module.css";
@value mainSurfaceLight, mainSurfaceDark, textLight, textDarkSecondary, strokeDark, strokeLight, cinnabar, gray3, gray2 from colors;

@keyframes close-top {
  0% {
    transform: translateY(-14px) rotate(0) scaleX(1);
  }
  50% {
    transform: translateY(0) rotate(0) scaleX(1);
  }
  100% {
    transform: translateY(0) rotate(45deg) scaleX(1.3);
  }
}

@keyframes open-top {
  0% {
    transform: translateY(0) rotate(45deg) scaleX(1.3);
  }
  50% {
    transform: translateY(0) rotate(0) scaleX(1);
  }
  100% {
    transform: translateY(-14px) rotate(0) scaleX(1);
  }
}

@keyframes close-bottom {
  0% {
    transform: translateY(14px) rotate(0) scaleX(1);
  }
  50% {
    transform: translateY(0) rotate(0) scaleX(1);
  }

  100% {
    transform: translateY(0) rotate(-45deg) scaleX(1.3);
  }
}

@keyframes open-bottom {
  0% {
    transform: translateY(0) rotate(-45deg) scaleX(1.3);
  }
  50% {
    transform: translateY(0) rotate(0) scaleX(1);
  }

  100% {
    transform: translateY(14px) rotate(0) scaleX(1);
  }
}

@keyframes open-middle {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes close-middle {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.container {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  align-content: space-between;
}

.container .bar {
  background-color: mainSurfaceDark;
}

.containerDarkMode .bar {
  background-color: textDarkSecondary;
}

.top {
  transform: translateY(-14px);
}

.topOpen {
  animation-name: open-top;
}

.topClose {
  animation-name: close-top;
}

.bottomOpen {
  animation-name: open-bottom;
}

.bottomClose {
  animation-name: close-bottom;
}

.middleOpen {
  animation-name: open-middle;
}

.middleClose {
  animation-name: close-middle;
}

.bottom {
  transform: translateY(14px);
}

.middle {
  transform: translateY(0);
}

.bar {
  transition: 0.3s background-color;
  animation-duration: 600ms;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  position: absolute;
  margin-top: -1.5px;
  top: 50%;
  width: 40px;
  height: 3px;
  border-radius: 3px;
}
