@value colors: "../../../styles/colors.module.css";
@value breakpoints: "../../../styles/breakpoints.module.css";
@value smallDesktop, mobile from breakpoints;
@value mainSurfaceDark, mainSurfaceDarkHover, mainSurfaceLight, strokeDark, strokeLight, textDark, gray4, gray3, gray5, gray6, textLight, textDarkSecondary, textLightSecondary from colors;

.container {
  background: textDark;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 7;
  color: textLight;
  transition: 0.3s background, 0.3s color, 0.3s transform, 0.3s opacity;
  cursor: pointer;
}

.container:hover {
  background: strokeLight;
}

.container:active {
  background: textDark;
}

.containerDarkMode {
  color: textDark;
  background: gray5;
}

.containerDarkMode:hover {
  background: gray3;
}

.containerDarkMode:active {
  background: gray5;
}
.containerMobile {
  position: unset;
  flex: 1;
  background: strokeLight;
  border-color: textDark;
  border-style: solid;
  border-width: 0;
}

.containerMobileDarkMode {
  background: gray3;
  border-color: gray5;
}

.containerMobile:first-of-type {
  border-right-width: 1px;
}

.containerMobile:last-of-type {
  border-left-width: 1px;
}

.icon {
  font-size: 1.7em;
}
