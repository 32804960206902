@value colors: "../../../styles/colors.module.css";
@value gray6, lightGray, gray5,gray3,gray2,gray1,strokeLightSecondary, strokeLight, textDarkSecondary, textLightSecondary, textDark, textLight, cinnabar from colors;

.container {
  padding: 10px 25px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s background, 0.3s border-left;
}

.container:hover {
  background: lightGray;
}

.container:active {
  background: unset;
}

.containerDarkMode {
}

.containerDarkMode:hover {
  background: gray3;
}

.containerDarkMode:active {
  background: unset;
}

.containerSelected,
.containerSelected:hover,
.containerSelected:active {
  cursor: auto;
  font-weight: 900;
  background: cinnabar;
  color: textDark;
}

.name {
}

.code {
  font-size: 0.8em;
  font-weight: 800;
}
