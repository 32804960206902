@value colors: "../../../styles/colors.module.css";
@value textDark, textLight from colors;

.container {
  overflow: auto;
  margin-bottom: 20px;
}

.a {
  font-family: "Roboto", sans-serif;
  font-size: 2em;
}

.label {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: textLight;
  transition: color 0.3s;
}

.labelDarkMode {
  color: textDark;
}
