@value colors: "../../styles/colors.module.css";
@value breakpoints: "../../styles/breakpoints.module.css";
@value smallDesktop, mobile from breakpoints;
@value cinnabar, strokeDark, strokeLight, textDark, textLight, strokeDarkSecondary, strokeLightSecondary, textDarkSecondary, textLightSecondary from colors;

.container {
  padding: 10px;
}

.formContainer {
}

.topRow {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.title {
  flex: 1;
  font-size: 1.5em;
  font-weight: 800;
  margin-left: 10px;
}

.email {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 5px 10px;
  margin-top: 10px;
  border-radius: 12px;
  border: 1px solid strokeLight;
  transition: 0.3s border;
}

.emailFocus {
  border: 1px solid strokeLightSecondary;
}

.emailDarkMode {
  border: 1px solid strokeDark;
}

.emailFocusDarkMode {
  border: 1px solid strokeDarkSecondary;
}

.emailIcon {
  margin-right: 5px;
  line-height: 0;
  font-size: 1.5em;
}

.emailInput {
  font-size: 0.9em;
  background: none;
  font-family: Roboto, sans-serif;
  outline: none;
  flex: 1;
  border: 0;
  color: textLight;
}

.emailInputDarkMode {
  color: textDark;
  background: none;
}

.emailInput::placeholder {
  color: textLightSecondary;
}

.emailInputDarkMode::placeholder {
  color: textDarkSecondary;
}

.videoContainer {
  width: 320px;
  margin: auto;
}

.video {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.comments {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  margin-top: 10px;
  border: 1px solid strokeLight;
  border-radius: 12px;
  transition: 0.3s border;
}

.commentsFocus {
  border: 1px solid strokeLightSecondary;
}

.commentsDarkMode {
  border: 1px solid strokeDark;
}

.commentsFocusDarkMode {
  border: 1px solid strokeDarkSecondary;
}

.commentsIcon {
  font-size: 1.5em;
  margin-right: 5px;
}

.commentsInput {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  height: 75px;
  background: none;
  outline: 0;
  border: 0;
  border-radius: 12px;
  font-family: Roboto, sans-serif;
  font-size: 0.9em;
}

.commentsInput::placeholder {
  color: textLightSecondary;
}

.commentsInputDarkMode {
  color: textDark;
  background: none;
}

.commentsInputDarkMode::placeholder {
  color: textDarkSecondary;
}

.action {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.sendIcon {
  margin-right: 10px;
}

.spinner {
  line-height: 0;
}

.button {
  outline: 0;
  border-radius: 12px;
  padding: 5px 15px;
  font-weight: 800;
  margin-right: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s background, 0.3s border, 0.3s opacity;
}

.button:last-child {
  margin-right: 0;
}

.sendButton {
  background: cinnabar;
  border: 1px solid cinnabar;
  color: textDark;
}

.sendButton:hover {
  opacity: 0.8;
}

.sendButton:active {
  opacity: 1;
}

.sendButtonDarkMode {
}

.sendButtonDarkMode:hover {
}

.sendButtonDarkMode:active {
}

.cancelButton {
  background: none;
  color: textLightSecondary;
  border: 1px solid textLightSecondary;
}

.cancelButton:hover {
  opacity: 0.8;
}

.cancelButton:active {
  opacity: 1;
}

.cancelButtonDarkMode {
  color: textDarkSecondary;
  border: 1px solid textDarkSecondary;
}

.thankYouContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.thankYouIcon {
  font-size: 5em;
}

.thankYouText {
  font-size: 2em;
}
