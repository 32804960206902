@value colors: "../../../styles/colors.module.css";
@value gray5, gray3, textDark, textDarkSecondary, strokeLight, strokeLightHover, textLightSecondary, cinnabar from colors;

.countryContainer {
  margin: 0 20px 10px 20px;
}

.noItemsAvailable {
  padding: 0 20px;
  height: 100%;
  color: textLightSecondary;
  transition: 0.3s color;
  font-weight: 900;
  font-size: 1.2em;
}

.noItemsAvailableDarkMode {
  color: textDarkSecondary;
}
