@value colors: "../../styles/colors.module.css";
@value breakpoints: "../../styles/breakpoints.module.css";

@value spaceCadet, spaceCadetHover, oxfordBlue, lightGray, gainsboro, raisinBlack, aliceBlue, aliceBlueHover, redMunsell from colors;
@value smallDesktop, largeDesktop, mobile from breakpoints;

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  85% {
    transform: scale(0.8);
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.container {
  position: fixed;
  z-index: 10000000000;
  bottom: 20px;
  border: 1px solid raisinBlack;

  background: redMunsell;
  color: aliceBlue;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 10px;
  max-width: 400px;
  box-shadow: 0 0 10px raisinBlack;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 20px;
  font-size: 2em;
  animation-name: pulse;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.message {
  font-family: "Roboto Condensed";
  font-size: 1.1em;
  font-weight: 500;
}
