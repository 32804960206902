@value colors: "../../../styles/colors.module.css";
@value mainSurfaceDark, strokeDarkSecondary, gray1, mainSurfaceLight, textDark, textLight, textDarkSecondary from colors;

.container {
  background-color: mainSurfaceLight;
  border: 1px solid textDarkSecondary;
  color: textLight;
  display: flex;
  align-items: center;
  border-radius: 12px;
  transition: 0.3s border-color;
  font-weight: normal;
  margin-bottom: 1rem;
  padding-left: 1rem;
}

.darkModeContainer {
  background-color: mainSurfaceDark;
  border: 1px solid strokeDarkSecondary;
  color: textDark;
}

input {
  outline: none;
  height: auto;
}

.inputContainer {
  height: 3.125rem;
  align-items: center;
  padding: 0 1rem;
  background-color: transparent;
  outline: none;
  border: none;
  padding-left: 1.125rem;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

.container:has(.textareaContainer) {
  align-items: baseline;
  padding: 1rem;
}

.textareaContainer {
  background-color: red;
  color: inherit;
  padding-left: 1.125rem;
  resize: none;
  height: 6.8rem;
  width: 100%;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  background-color: inherit;
  border: none;
}

.icon {
  font-size: 1.2rem;
}

.inputInFocus:not(.darkModeContainer) {
  border-color: strokeDarkSecondary;
}

.inputInFocus {
  border-color: gray1;
}
