@value colors: "../../styles/colors.module.css";
@value gray1, gray2, gray3, gray5, gray4, gray6, textDarkSecondary, secondarySurfaceLight, strokeDarkSecondary, strokeDark, strokeLight, textLight from colors;

.featureLinkItem {
  transition: 0.3s background, 0.3s color, 0.3s border-bottom;
  color: textLight;
  background: secondarySurfaceLight;
  border-bottom: 1px solid strokeLight;
  display: flex;
  flex-direction: row;
  height: 90px;
  align-items: center;
  cursor: pointer;
}

.featureLinkItem:last-child {
  border-bottom: 0;
}

.featureLinkItem:hover {
  background: strokeLight;
}

.featureLinkItem:active {
  background: secondarySurfaceLight;
}

.featureLinkItemDarkMode {
  background: gray4;
  border-bottom: 1px solid strokeDarkSecondary;
  color: textDarkSecondary;
}

.featureLinkItemDarkMode:hover {
  background: gray3;
}

.featureLinkItemDarkMode:active {
  background: gray4;
}

.featureLinkIconContainer {
  width: 130px;
}

.featureLinkIcon {
  font-size: 2em;
  padding-left: 48px;
}

.featureLinkText {
  /* font-size: 1.3em; */
  font-weight: 700;
}
