@value colors: "../../../styles/colors.module.css";
@value gray5, textDark, textLight, textDarkSecondary, textLightSecondary from colors;

.container {
  width: 100%;
  color: textLight;
  box-sizing: border-box;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  background: textDark;
  transition: background 0.3s, color 0.3s;
}

.containerDarkMode {
  background: gray5;
  color: textDark;
}

.innerContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button {
  font-size: 1.8em;
  width: 30px;
  height: auto;
  cursor: pointer;
}
.cancelButton {
  background: none;
  border: none;
  padding: 0 20px;
  font-size: 1.05em;
  transition: 0.3s color;
  color: textLightSecondary;
  cursor: pointer;
}

.cancelButtonDarkMode {
  color: textDarkSecondary;
}

/* .button {
  padding: 0 20px;
} */

.buttonSymmetric {
  font-size: 1.8em;
  cursor: pointer;
  padding: 0 20px;
}

.button:first-of-type {
  padding: 0 10px 0 20px;
}

.button:last-of-type {
  padding: 0 20px 0 10px;
}

.settingsButtonDarkMode {
}
