@value colors: "../../styles/colors.module.css";
@value textDark, textLight, textDarkSecondary, strokeLightSecondary, gray1, textLightSecondary from colors;

.container {
  width: 50px;
  height: 50px;
  transition:
    0.3s background,
    0.3s color;
  color: textLight;
  cursor: pointer;
  outline: none;
  border: none;
  text-align: center;
  position: relative;
  display: flex;
  background: none;
  justify-content: center;
  align-items: center;
}

.container:hover {
  color: strokeLightSecondary;
}

.container:active {
  color: textLight;
}

.containerDarkMode {
  color: textDark;
}

.containerDarkMode:hover {
  color: gray1;
}

.containerDarkMode:active {
  color: textDark;
}

.icon {
  font-size: 3em;
}
