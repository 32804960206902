@value colors: "../../../styles/colors.module.css";
@value gray5, textDark, textLight from colors;

.container {
  position: absolute;
  left: 0;
  display: flex;
  overflow-y: auto;
  transition: transform 0.3s, opacity 0.3s, background 0.3s;
  width: 100%;
  box-sizing: border-box;
  overflow-x: visible;
  z-index: 1;
  flex-direction: column;
  padding: 20px;
  background: textDark;
  pointer-events: none;
  opacity: 0;
  transform: scaleY(0.25);
  border-radius: 0 0 10px 10px;
  transform-origin: top center;
  fill: textLight;
  color: textLight;
}

.containerRounded {
  border-radius: 0 0 10px 10px;
}

.containerDarkMode {
  background: gray5;
  fill: textDark;
  color: textDark;
}

.innerContainer {
  box-sizing: border-box;
  padding: 0 10px;
  overflow-x: visible;
  display: flex;
  flex-wrap: wrap;
}

.show {
  pointer-events: auto;
  opacity: 1;
  transform: scaleY(1);
}

.bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow {
  padding: 5px 10px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  transform-origin: center center;
  transform: rotate(-90deg);
  transition: transform 0.3s;
  transition-delay: 0.3s;
}

.arrowOpen {
  transform: rotate(90deg);
}
