@value colors: "../../styles/colors.module.css";
@value gray5, mainSurfaceLight, textDark, textLight from colors;

.container {
  width: 25rem;
  height: auto;
  background-color: gray5;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  border-radius: 12px;
  padding: 36px;
  color: textDark;
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: small;
  margin-bottom: 0.75rem;
}

.heading {
  margin: 0;
}

.lightMode {
  background-color: mainSurfaceLight;
  color: textLight;
}

.closeButton {
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  padding: 0;
  transition: 0.3s color;
}

.feedbackFormContainer {
  width: inherit;
  overflow: auto;
  margin-top: 1rem;
  position: relative;
}

.title {
  margin: 1.25rem 0;
  text-align: center;
  font-weight: bold;
}

.xMarkIcon {
  font-size: 2.6em;
}

.spinner {
  line-height: 0;
}
