@value colors: "../../styles/colors.module.css";
@value textDark, textLight, mainSurfaceDark,darkSeparator, gray3, mainSurfaceLight, strokeDark, strokeDarkSecondary, strokeLight, textLightSecondary, textDarkSecondary, secondarySurfaceLight from colors;

.container {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  bottom: 0;
  justify-content: center;
  outline: none;
  transition: background 0.3s;
  background: textDark;
  cursor: col-resize;
  box-sizing: border-box;
}

.containerDarkMode {
  background: darkSeparator;
}

.handler {
  height: 100px;
  width: 5px;
  box-sizing: border-box;
  border: solid gray3;
  border-width: 0 1px 0 1px;
  transition: border 0.3s;
}

.handlerDarkMode {
  border: solid gray3;
  border-width: 0 1px 0 1px;
}
