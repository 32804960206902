@value colors: "../../../styles/colors.module.css";
@value textDark, textLight, strokeDarkSecondary, strokeLightSecondary, mainSurfaceLight, mainSurfaceDark, textDarkSecondary, textLightSecondary, cinnabar from colors;

.versionsContainer {
  border-bottom: 1px solid red;
  margin: 0 20px 20px 20px;
  padding-bottom: 10px;
  transition: 0.3s color, 0.3s border-bottom;
  font-weight: 700;
  color: textLightSecondary;
  border-bottom: 1px solid strokeLightSecondary;
  font-size: 1.2em;
}

.versionsContainerDarkMode {
  border-bottom: 1px solid strokeDarkSecondary;
  color: textDarkSecondary;
}
