@value colors: "../../../../styles/colors.module.css";
@value textDark, textLight, strokeDarkSecondary, strokeLightSecondary, strokeLight, strokeLightHover, mainSurfaceLight, mainSurfaceDark, textDarkSecondary, textLightSecondary, gray5,secondarySurfaceLight,gray3,  gray4, cinnabar from colors;

.button {
  cursor: pointer;
  transition: 0.3s border, 0.3s color, 0.3s background;
  width: calc(10% - 5px);
  aspect-ratio: 1;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  border: 1px solid strokeLightSecondary;
  color: textLightSecondary;
  outline: 0;
}

.button:hover {
  background: strokeLight;
}

.button:active {
  background: none;
}

.buttonDarkMode {
  color: textDarkSecondary;
  background: gray5;
  border: 1px solid strokeDarkSecondary;
}

.buttonDarkMode:hover {
  background: gray3;
}

.buttonDarkMode:active {
  background: gray5;
}

.buttonSelected,
.buttonSelected:hover,
.buttonSelected:active {
  background: cinnabar;
  color: textDark;
  border: 1px solid cinnabar;
  font-weight: 700;
}
