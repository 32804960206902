@value colors: "../../styles/colors.module.css";
@value breakpoints: "../../styles/breakpoints.module.css";
@value smallDesktop, mobile from breakpoints;
@value mainSurfaceDark, mainSurfaceLight, strokeDark, strokeLight, textDark, gray3, gray5, textLight, textDarkSecondary, textLightSecondary from colors;

.container {
  transition: 0.3s background, 0.3s transform;
  will-change: transform;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100000;
  background: white;
}

.containerDarkMode {
  background: gray5;
}

.header {
  display: flex;
  position: relative;
  align-items: center;
  background: textDark;
  transition: 0.3s background;
}

.headerDarkMode {
  background: gray5;
}
.innerContainer {
  transition: 0.3s background, 0.3s transform;
  will-change: transform;
  background: white;
  overflow-y: auto;
}

.innerContainerDarkMode {
  background: gray5;
}

.label {
  position: absolute;
  line-height: 60px;
  top: 0;
  left: 60px;
  text-align: left;
  height: 60px;
  width: 140px;
}

.faIconWrapper {
  top: 0;
  left: 0;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  line-height: 60px;
  font-size: 2em;
}

.textLanguage {
  line-height: 1;
  font-size: 0.6em;
  font-weight: 400;
}

.themeSwitchContainer {
  margin-left: 50px;
  pointer-events: none;
}

.externalLinksContainer {
  margin: 10px;
}

.internalLinksContainer {
  margin: 25px;
}

.internalLink {
  font-size: 1.2em;
  transition: 0.3s color;
  color: textLightSecondary;
  margin-bottom: 20px;
}

.internalLinkDarkMode {
  color: textDarkSecondary;
}
