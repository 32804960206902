@value colors: "../../styles/colors.module.css";
@value textLightSecondary, textDarkSecondary, gray4 from colors;

.container {
  /* margin-left: 5px; */
  color: white;
  background: gray4;
  border-radius: 10px;
  font-size: 0.8em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

.explanationText {
  max-width: 300px;
  text-align: center;
}

.explanationVideo {
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  aspect-ratio: 1 / 1;
  margin: 10px 0;
  border-radius: 10px;
}

.video {
  border-radius: 10px;
}

.tooltip {
  border-radius: 10px !important;
  font-family: Roboto, serif !important;
  font-size: 1.1em !important;
  z-index: 1000000;
  display: flex;
  align-items: center;
  flex-direction: column;
}
