@value colors: "../../../../styles/colors.module.css";
@value gray5, gray3, textDark, textDarkSecondary, strokeLight, strokeLightHover, textLightSecondary, cinnabar from colors;

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  transition: 0.3s background, 0.3s color;
  outline: none;
  padding: 15px 20px;
  cursor: pointer;
  border: 0;
  outline: none;
  background: none;
  width: 100%;
}

.container:hover {
  background: strokeLight;
}

.container:active {
  background: none;
}

.containerDarkMode {
  color: textDarkSecondary;
}

.containerDarkMode:hover {
  background: gray3;
}

.containerDarkMode:active {
  background: none;
}

.containerSelected,
.containerSelected:hover,
.containerSelected:active {
  background: cinnabar;
  font-weight: 700;
  color: white;
}
