.container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.iconContainer {
  margin: 15px 20px;
  height: 45px;
  width: 45px;
  background: white;
  border-radius: 50%;
}

.icon {
  width: 45px;
  height: auto;
}

.textContainer {
  margin: 10px 5px 10px 0;
  flex: 1;
}

.nameContainer {
  line-height: 1.2;
}

.languageContainer {
  margin-top: 10px;
}
