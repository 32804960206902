@value colors: "../../../styles/colors.module.css";
@value textDark, textLight, strokeDarkSecondary, strokeLightSecondary, mainSurfaceLight, mainSurfaceDark, textDarkSecondary, textLightSecondary, cinnabar from colors;

.container {
  color: textLightSecondary;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
}

.containerDarkMode {
  color: textDark;
}

.titleContainer {
  margin: 0 20px 20px 0;
  text-align: center;
  font-weight: 700;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.keysContainer {
}

.keyContainer {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.keyContainer:last-child {
  margin-bottom: 0;
}

.text {
  margin-left: 5px;
}
