@value colors: "../../../styles/colors.module.css";
@value gray5, gray6, gray2, textDark, textLight, strokeLightSecondary, textDarkSecondary, gray2 from colors;

.modalContainer {
  border-radius: 12px;
  position: absolute;
  background: textDark;
  margin: 22px 0 0 -3px;
  overflow: hidden;
  color: textLight;
  border: 1px solid strokeLightSecondary;
}

.modalContainerDarkMode {
  background: gray6;
  color: textDarkSecondary;
  border: 1px solid gray2;
}
