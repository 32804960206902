html,
body,
#root {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  transition: 0.3s background;
  overscroll-behavior: none;
}

body {
  background: #161616;
}
