@value colors: "../../../../styles/colors.module.css";
@value gray4,gray5,secondarySurfaceLight, textDarkSecondary, textLightSecondary from colors;

.container {
}

.innerContainer {
  display: flex;
  margin: 14px 0 0;
}

.videoTypesContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  padding: 7px;
  box-sizing: border-box;
  background: white;
  border-radius: 12px;
  transition: 0.3s background;
}

.videoTypesContainerDarkMode {
  background: gray5;
}
