@value colors: "../../../styles/colors.module.css";
@value gray4,gray5,secondarySurfaceLight, textDarkSecondary, textLightSecondary, cinnabar from colors;

.container {
  /* color: textLightSecondary; */
  transition: 0.3s color, 0.3s background;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.containerDarkMode {
  /* color: textDarkSecondary; */
}

.containerMobile {
  flex-direction: column;
  align-items: start;
}

.logoContainer {
  margin-right: 20px;
}

.copyrightText {
  font-size: 0.9em;
}

.logo {
  height: 35px;
}

.qualityChecks {
  margin-left: 5px;
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
}
