.enter {
  opacity: 0;
  pointer-events: none;
}

.enterActive {
  opacity: 1;
  transition: opacity 300ms;
  pointer-events: active;
}

.enterDone {
  opacity: 1;
  pointer-events: active;
}

.exit {
  opacity: 1;
  pointer-events: none;
}

.exitActive {
  transition: opacity 300ms;
  opacity: 0;
  pointer-events: none;
}

.exitDone {
  opacity: 0;
  pointer-events: none;
}
