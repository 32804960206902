@value colors: "../../../../styles/colors.module.css";
@value gray3, gray4,gray5, secondarySurfaceLightHover, textDark, textLight, secondarySurfaceLight, textDarkSecondary, textLightSecondary from colors;
@value breakpoints: "../../../../styles/breakpoints.module.css";
@value mobile from breakpoints;

.container {
}

.innerContainer {
  display: flex;
  margin: 14px 0 0;
}

.innerContainerDarkMode {
}

.bookChapterContainer {
  transition: 0.3s color, 0.3s background;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12px 0 0 12px;
  font-weight: 900;
  font-size: 1.2em;
  background: secondarySurfaceLightHover;
  color: textLightSecondary;
}

.bookChapterContainerDarkMode {
  background: gray4;
  color: textDarkSecondary;
}

.bookChapterText {
  padding: 0 25px;
  box-sizing: border-box;
}

.verseRangesContainer {
  flex: 1;
  box-sizing: border-box;
  background: textDark;
  font-weight: 700;
  color: textLightSecondary;
  border-radius: 0 12px 12px 0;
  transition: background 0.3s, color 0.3s;
}

.group {
  border-bottom: 1px solid gray3;
  margin-left: 5px;
}

.group:last-of-type {
  border-bottom: 0;
}

.subTitle {
  font-size: 1.15em;
  margin-top: 20px;
  margin-left: 15px;
}

.verseRangesContainerDarkMode {
  background: gray5;
  color: textDarkSecondary;
}

.innerContainerVertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.bookChapterContainerVertical {
  border-radius: 12px 12px 0 0;
  font-size: 1.2em;
  height: 40px;
}

.bookChapterTextVertical {
  padding: 0 20px;
}

.verseRangesContainerVertical {
  border-radius: 0 12px 12px 12px;
  width: 100%;
}
