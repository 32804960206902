@value colors: "../../../styles/colors.module.css";
@value textLight, textDark, gray2 from colors;

.thumbnailContainer {
  background: black;
  height: 80px;
  border-radius: 10px;
  margin-right: 20px;
}

.thumbnail {
  height: 80px;
  width: auto;
  aspect-ratio: 16/9;
  border-radius: 10px;
}

.textContainer {
  transition: 0.3s color;
}

.text {
}

.highlight {
  color: white;
  background: gray2;
}

.reference {
  margin-top: 10px;
  font-size: 0.8em;
  font-weight: 900;
}
